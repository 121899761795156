import { render, staticRenderFns } from "./Rigist.vue?vue&type=template&id=19c57b67&scoped=true&"
import script from "./Rigist.vue?vue&type=script&lang=js&"
export * from "./Rigist.vue?vue&type=script&lang=js&"
import style0 from "./Rigist.vue?vue&type=style&index=0&id=19c57b67&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c57b67",
  null
  
)

export default component.exports