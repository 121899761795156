import request from '@/utils/request'

export function sendSmsVerifyCode(receiver, bizType) {
  return request(
      'get',
      '/v1',
     `/mc/user/msg/verifycode/${receiver}/${bizType}`
  );
}

// 登录状态下获取验证码
export function sendSmsVerifyCodeOnLine(mid, bizType) {
  return request(
      'get',
      '/v1',
     `/mc/user/msg/sms/verifycode/${mid}/${bizType}`
  );
}
